import React from 'react';
import Footer from '../Components/Footer';

// Importing all the images dynamically for Events
const eventImages = [];
for (let i = 1; i <= 72; i++) {
    eventImages.push(require(`../Components/Event/event (${i}).jpeg`));
}

// Importing all the images dynamically for Nari
const nariImages = [];
for (let i = 1; i <= 89; i++) {
    nariImages.push(require(`../nari/function (${i}).jpeg`));
}


const htsimages = [];
for (let i = 1; i <= 64; i++) {
    nariImages.push(require(`../Components/Update/hts (${i}).jpeg`));
}


// Combine both arrays
const allImages = [...eventImages, ...nariImages, ...htsimages];

const Event = () => {
    return (
        <>
            <div className="Heading">
                Home / Events
            </div>
            <div className="event-container">
                <div className="event-grid">
                    {/* Mapping through the images array to display them */}
                    {allImages.map((image, index) => (
                        <div key={index} className="event-item">
                            <img src={image} alt={`Event image ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Event;
